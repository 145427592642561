<ng-container>

  <div class="no-overlay" *ngIf="!overlay">
    <mm-tool-bar title="display.privacy.policies.title"></mm-tool-bar>
    <div class="inner">
      <div class="loader" *ngIf="loading"></div>
      <div class="col-sm-12 page configuration privacy-policy" *ngIf="!loading">
        <div *ngIf="privacyPolicy" [innerHTML]="privacyPolicy.html"></div>
        <p *ngIf="!privacyPolicy">{{'privacy.policy.not.found' | translate}}</p>
      </div>
    </div>
  </div>

  <div id="privacy-policy-wrapper" class="overlay" *ngIf="overlay">
    <div class="container">
      <div class="loader" *ngIf="loading"></div>
      <div class="content" *ngIf="!loading && privacyPolicy">
        <div class="html-content" [innerHTML]="privacyPolicy?.html"></div>
        <div class="footer text-right">
          <button class="btn btn-primary" *ngIf="!accepting && !loading" (click)="accept()">{{'privacy.policy.accept' | translate}}</button>
          <div *ngIf="accepting">
            <span class="loader inline small"></span>
            <button class="btn btn-primary disabled">{{'privacy.policy.accept' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
