<section class="sidebar-filter">
  <div
    class="sidebar-backdrop"
    [ngClass]="{ hidden: !isOpen }"
    (click)="toggleSidebarFilter()"
    (keydown.escape)="toggleSidebarFilter()">
  </div>

  <div class="sidebar-main" [ngClass]="{ hidden: !isOpen }">
    <div class="sidebar-header">
      <p class="sidebar-title">
        {{ 'reports.sidebar.filter.title' | translate }}
      </p>
      <a class="sidebar-close" (click)="toggleSidebarFilter()">
        <mat-icon svgIcon="icon-close"></mat-icon>
      </a>
    </div>

    <div class="sidebar-body">
      <mat-accordion multi>
        <!-- Facility -->
        <div class="filter-options-container" *ngIf="userFacilities.length > 1">
          <span class="filter-options-title">
            {{ facilityFilterLabel | translate }}
          </span>
          <ng-container *ngFor="let facility of userFacilities">
            <div class="filter-radio-option">
              <label class="filter-option-wrapper">
                <input
                  type="radio"
                  name="facility"
                  [value]="facility"
                  class="filter-option-radio"
                  [checked]="facility._id === selectedFacility._id"
                  (change)="fetchAggregateTargetsByFacility(facility)">
                <span class="filter-option-label">
                  {{ facility.name }}
                </span>
              </label>
            </div>
          </ng-container>
        </div>
      </mat-accordion>

      <mat-accordion multi>
        <!-- Reporting Period -->
        <div class="filter-options-container">
          <span class="filter-options-title">
            {{ 'analytics.target.aggregates.reporting_period' | translate }}
          </span>
          <div class="filter-radio-option" *ngFor="let period of reportingPeriods">
            <label class="filter-option-wrapper">
              <input
                type="radio"
                name="reporting-period"
                class="filter-option-radio"
                [value]="period.value"
                [(ngModel)]="selectedReportingPeriod"
                (change)="fetchAggregateTargetsByReportingPeriod()">
              <span class="filter-option-label">
                {{ period.label | translate }}
              </span>
            </label>
          </div>
        </div>
      </mat-accordion>
    </div>

    <div class="sidebar-footer">
      <button type="button" class="btn btn-primary" (click)="toggleSidebarFilter()">
        {{ 'reports.sidebar.filter.submit' | translate }}
      </button>
    </div>
  </div>
</section>
