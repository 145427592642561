<mm-tool-bar title="about"></mm-tool-bar>
<div class="inner">
  <div class="col-sm-12 page about scrolling">

    <div>

      <mat-card>
        <mat-card-header>
          <mat-card-title translate>reload.app</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <button mat-button color="primary" (click)="reload()" translate>Reload</button>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title (click)="secretDoor()" translate>about</mat-card-title>
        </mat-card-header>
        <mat-card-content>

          <div>
            <label translate>version</label>
            <p test-id="about-version">{{version}}</p>
          </div>

          <div>
            <label translate>debug.supported_browser</label>
            <div>
              <span class="fa fa-check-square browser-support-icon-green"></span>
              <ng-template #unsupportedBrowser>
                <span class="fa fa-times browser-support-icon-red"></span>
                <a target="_blank" rel="noopener noreferrer" href="https://docs.communityhealthtoolkit.org/core/releases/#requirements">
                  {{'debug.supported_browser.see_requirements' | translate}}
                </a>
                <p class="browser-support-message">
                  <span>{{'login.unsupported_browser' | translate}}</span>
                  <ng-container [ngSwitch]="browserSupport.outdatedComponent">
                    <span *ngSwitchCase="'chtAndroid'">{{'login.unsupported_browser.outdated_cht_android' | translate}}</span>
                    <span *ngSwitchCase="'webviewApk'">{{'login.unsupported_browser.outdated_webview_apk' | translate}}</span>
                    <span *ngSwitchCase="'browser'">{{'login.unsupported_browser.outdated_browser' | translate}}</span>
                  </ng-container>
                </p>
              </ng-template>
            </div>
          </div>

          <div *ngIf="androidDeviceInfo?.app?.version">
            <label translate>android_app.version.title</label>
            <p>{{androidDeviceInfo.app.version}}</p>
          </div>

          <div *ngIf="androidDeviceInfo?.app?.packageName">
            <label translate>android_app.package_name.title</label>
            <p>{{androidDeviceInfo.app.packageName}}</p>
          </div>

          <div *ngIf="androidDeviceInfo?.app?.versionCode">
            <label translate>android_app.version_code.title</label>
            <p>{{androidDeviceInfo.app.versionCode}}</p>
          </div>

          <div *ngIf="androidDeviceInfo?.software?.androidVersion">
            <label translate>android_app.os_android_version.title</label>
            <p>
              {{androidDeviceInfo.software.androidVersion}}
              <span *ngIf="androidDeviceInfo?.software?.osApiLevel">(SDK {{androidDeviceInfo.software.osApiLevel}})</span>
            </p>
          </div>

          <div>
            <label translate>ddoc.version</label>
            <p>{{remoteRev}}</p>
          </div>

          <div>
            <label translate>clientDdoc.version</label>
            <p>{{localRev}}</p>
          </div>

          <div>
            <label translate>url</label>
            <p>{{url}}</p>
          </div>

          <div>
            <label translate>User Name</label>
            <p>{{userCtx.name}}</p>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title translate>debug.db_info.title</mat-card-title>
        </mat-card-header>
        <mat-card-content>

          <div>
            <label translate>debug.db_info.name</label>
            <p>{{dbInfo?.db_name}}</p>
          </div>

          <div>
            <label translate>debug.db_info.docs</label>
            <p>{{dbInfo?.doc_count}}</p>
          </div>

          <div>
            <label translate>debug.db_info.seq</label>
            <p>{{dbInfo?.update_seq}}</p>
          </div>

          <div>
            <label translate>replication.last.success.to</label>
            <p *ngIf="!replicationStatus.disabled && replicationStatus.lastSuccessTo">{{replicationStatus.lastSuccessTo | simpleDateTime}}</p>
            <p *ngIf="!replicationStatus.disabled && !replicationStatus.lastSuccessTo" translate>replication.last.success.unknown</p>
            <p *ngIf="replicationStatus.disabled" translate>replication.disabled</p>
          </div>

          <div>
            <label translate>replication.last.success.from</label>
            <p *ngIf="!replicationStatus.disabled && replicationStatus.lastSuccessFrom">{{replicationStatus.lastSuccessFrom | simpleDateTime}}</p>
            <p *ngIf="!replicationStatus.disabled && !replicationStatus.lastSuccessFrom" translate>replication.last.success.unknown</p>
            <p *ngIf="replicationStatus.disabled" translate>replication.disabled</p>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="androidDataUsage">
        <mat-card-header>
          <mat-card-title translate>android_app.data_usage.title</mat-card-title>
          <mat-card-subtitle translate>android_app.data_usage.description</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>

          <h4 translate>android_app.data_usage.system.title</h4>
          <div>
            <div>
              <label translate>android_app.data_usage.rx</label>
              <p translate [translateParams]="{ number: androidDataUsage?.system?.rx | number }">number.bytes</p>
            </div>
            <div>
              <label translate>android_app.data_usage.tx</label>
              <p translate [translateParams]="{ number: androidDataUsage?.system?.tx | number }">number.bytes</p>
            </div>
          </div>

          <h4 translate>android_app.data_usage.app.title</h4>
          <div>
            <div>
              <label translate>android_app.data_usage.rx</label>
              <p translate [translateParams]="{ number: androidDataUsage?.app?.rx | number }">number.bytes</p>
            </div>
            <div>
              <label translate>android_app.data_usage.tx</label>
              <p translate [translateParams]="{ number: androidDataUsage?.app?.tx | number }">number.bytes</p>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="partners?.length" class="partners">
        <mat-card-header>
          <mat-card-title translate>partner.supporting</mat-card-title>
        </mat-card-header>

        <ul class="list-inline">
          <li *ngFor="let partner of partners">
            <span [innerHTML]="partner | partnerImage"></span>
          </li>
        </ul>

      </mat-card>

      <div class="powered-by">
        <p translate>about.cht</p>
        <a href="https://communityhealthtoolkit.org" target="_blank" rel="noopener noreferrer">
          <img src="/webapp/img/cht-logo-light.png" alt="Community Health Toolkit" />
        </a>
      </div>

    </div>

  </div>
</div>
