<div class="tool-bar">
  <div class="inner">
    <ng-content/>
    <h2 class="ellipsis-title"> {{ title | translate }}</h2>
    <button class="app-menu-button" mat-icon-button (click)="openMenu()" aria-label="Application menu">
      <mat-icon fontIcon="fa-bars"></mat-icon>
    </button>
    <mm-navigation></mm-navigation>
  </div>
</div>
