<section class="more-options-menu-container" *ngIf="displayExportOption()">
  <button mat-icon-button [matMenuTriggerFor]="moreOptionsMenu" aria-label="Actions menu">
    <mat-icon fontIcon="fa-ellipsis-v"></mat-icon>
  </button>

  <mat-menu #moreOptionsMenu="matMenu" xPosition="before">
    <button mat-menu-item test-id="export-messages" type="button" (click)="exportMessages.emit()" [disabled]="!conversations?.length">
      <mat-icon fontIcon="fa-arrow-down"></mat-icon>
      <span>{{ 'Export' | translate }}</span>
    </button>
  </mat-menu>
</section>
