<div class="mm-form-type-filter" [id]="fieldId">
  <ul class="filter-options">
    <ng-container
      *ngFor="let facility of displayedFacilities; trackBy: trackByFn"
      [ngTemplateOutlet]="facilityTemplate"
      [ngTemplateOutletContext]="{ facility: facility, facilityDepth: 0 }">
    </ng-container>
  </ul>
</div>

<ng-template
  #facilityTemplate
  let-facility="facility"
  let-facilityDepth="facilityDepth"
  let-parent="parent"
  let-selectedParent="selectedParent">

  <li
    class="accordion-facility-list"
    role="presentation"
    [class.selected]="filter?.selected.has(facility.doc._id)"
    [class.disabled]="selectedParent || disabled"
    [class.accordion-facility-list-expand]="facility.toggle"
    [class.accordion-facility-caret]="facility?.children?.length">

    <span
      class="fa accordion-facility-check"
      [ngClass]="filter?.selected.has(facility.doc._id) ? 'fa-check-square' : 'fa-square-o'"
      (click)="select(selectedParent, facility, true)">
    </span>
    <a
      class="accordion-facility-title"
      role="menuitem"
      tabindex="-1"
      [attr.data-value]="facility?.doc?._id"
      (click)="select(selectedParent, facility)">
      {{ facility.label | async }}
    </a>
    <span
      class="fa accordion-facility-chevron"
      [class.fa-caret-up] = facility.toggle
      [class.fa-caret-down] = !facility.toggle
      (click)="select(selectedParent, facility)">
    </span>
    <ul class="mm-dropdown-submenu" *ngIf="facility?.children?.length">
      <ng-container
        *ngFor="let child of facility?.children; trackBy: trackByFn"
        [ngTemplateOutlet]="facilityTemplate"
        [ngTemplateOutletContext]="{ facility: child, parent: facility, selectedParent: filter?.selected.has(facility.doc._id), facilityDepth: facilityDepth + 1 }">
      </ng-container>
    </ul>

  </li>
</ng-template>
