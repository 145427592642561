<mm-tool-bar title="error.general.title"></mm-tool-bar>

<div class="inner">
  <div *ngIf="translationsLoaded" class="col-sm-12 page help">
    <h3>{{ error.title | translate }}</h3>
    <p>{{ error.description | translate }}</p>
    <br>
    <a (click)="exit()">{{ (error.showBackLink ? 'Back' : 'reload.app') | translate }}</a>
  </div>
  <div *ngIf="!translationsLoaded" class="col-sm-12 page help">
    <div class="loader inline small" *ngIf="!timeoutElapsed"></div>
    <div *ngIf="timeoutElapsed">
      <h3>Error</h3>
      <p>Error loading. Check your internet connection and try again or check with an administrator.</p>
      <br>
      <a (click)="exit()">Reload application</a>
    </div>
  </div>
</div>

